/**
 * Variables
*/

/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
$black: #000;
$white: #fff;

$body-background-color: #000;
$foreground-color: #888888;
$caption-color: #464646;
$accent-color: #999;
$link-color: #ec1f4e;

/* Components */
