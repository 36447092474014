body, html {
  background: $body-background-color;

  font-family: 'Lato', arial, serif;
  font-size: 20px;
  line-height: 2rem;
  color: $foreground-color;
}

h1, h2, h3, h4 {
  font-family: 'Patua One', Georgia, 'Times New Roman', Times, serif;
}

a {
  color: white;
  text-decoration: underline;
  padding: 0.8rem 0.8rem 0.8rem 0.4rem;

  transition: all 0.3s ease-in-out;

  &:hover {
    color: $foreground-color;
  }
}

.name {
  opacity: 0;
}

header {
  display: none;
}

.footer {
  color: $foreground-color;
  text-align: center;
  position: relative;
  z-index: 1;

  p {
    font-size: 0.8rem;

    a {
      color: rgba(255,255,255,0.8);
      padding-right: 0.3rem;
    }
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.viz {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 0;
}

.wrap {
  max-width: 950px;
  margin: 0 auto;
}
.wrap-g {
  display: block;
  position: relative;
  z-index: 1;
  
  background-color: rgba(0,0,0,0.7);
  // background: radial-gradient(circle, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.45) 45%, rgba(0,0,0,0) 70%);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0, 0,0) 100%);
}

.push {
  height: 65vh;
  pointer-events: none;
  opacity: 0;
}

.content {
  position: relative;
  width: 100%;
  min-width: 375px;

  margin: 4rem auto;
  padding: 4rem 0;
  text-align: center;

  &.greeting {
    margin-top: 0;
  }

  p {
    padding: 0 3rem;
  }
}

.head {
  font-size: 2.5rem;
  color: $foreground-color;
  margin-bottom: 3rem;
  margin: 1rem auto;

  &:first-child {
    margin: 0 0 1rem;
  }
}

.skills {
  width: 65%;
  margin: 0 auto;

  &_list {
    li {
      display: inline-block;
      padding: 1rem;
    }
  }
}

.patrons {
  &_list {
    li {
      display: block;
      margin-bottom: 0.3rem;
    }
  }
}

.works {
  width: 65%;

  &_list {
    li {
      display: inline-block;
      margin-bottom: 0.3rem;

      padding: 0.3rem;

      a {
        color: $foreground-color;
        text-decoration: underline;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

.rotate-list {
  transform: perspective(1500px) translateZ(0);
  perspective: 800px;
  perspective-origin: center;
  -webkit-font-smoothing: subpixel-antialiased;

  li {
    transform: perspective(1500px) rotateY(0);
    transform-style: preserve-3d;
    transition: all 0.3s ease-in-out;

    padding-left: 0.3rem;

    &:hover {
      transform: perspective(3000px) rotateY(var(--rotY)) rotateZ(var(--rotZ));
      color: white;
    }
  }
}
